<template>
	<div class="login-container">
		<div class="input-box">
			<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on"
				label-position="left">

				<div class="title-container">
					<h3 class="title">Rolum在线学驾照</h3>
				</div>

				<el-form-item prop="username">
					<span class="svg-container">
						<svg-icon class="el-icon-user-solid" />
					</span>
					<el-input ref="username" v-model="loginForm.username" placeholder="请输入用户名" name="username"
						type="text" tabindex="1" autocomplete="on" />
				</el-form-item>

				<el-form-item prop="password">
					<span class="svg-container el-icon-s-cooperation">
						<svg-icon icon-class="password" />
					</span>
					<el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
						placeholder="请输入密码" name="password" tabindex="2" autocomplete="on" @keyup.native="checkCapslock"
						@blur="capsTooltip = false" @keyup.enter.native="submitForm" show-password />
					<span class="show-pwd" @click="showPwd">
						<svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
					</span>
				</el-form-item>

				<el-form-item>


					<div style="position:relative;">


						<el-button :loading="loading" type="primary" style="width:100%;margin-bottom:20px;"
							@click.native.prevent="submitForm">登陆</el-button>
						<el-button type="warning" style="width:100%;margin-left: 0px;"
							@click.native.prevent="studentRegister">账号注册
						</el-button>


					</div>
				</el-form-item>
			</el-form>

		</div>
		<el-dialog title="其他登陆" :visible.sync="showDialog">
			敬请期待
			<br>
			<br>
			<br>
			<social-sign />
		</el-dialog>
		<div style="width: 100%;text-align: center;font-size: 14px;color: white;">备案号：新ICP备2022000230号-1</div>
	</div>
</template>

<script>
	// import { validUsername } from '@/utils/validate'
	// import SocialSign from './components/SocialSignin'
	import {
		ElMessage
	} from 'element-plus'
	export default {
		name: 'Login',
		// components: { SocialSign },
		data() {
			return {
				loginForm: {
					username: '',
					password: ''
				},
				loginRules: {
					username: [{
						required: true,
						trigger: 'blur'
					}],
					password: [{
						required: true,
						trigger: 'blur'
					}]
				},
				passwordType: 'password',
				capsTooltip: false,
				loading: false,
				showDialog: false,
				redirect: undefined,
				otherQuery: {}
			}
		},
		watch: {
			$route: {
				handler: function(route) {
					const query = route.query
					if (query) {
						this.redirect = query.redirect
						this.otherQuery = this.getOtherQuery(query)
					}
				},
				immediate: true
			}
		},
		created() {
			// window.addEventListener('storage', this.afterQRScan)
		},
		mounted() {
			if (this.loginForm.username === '') {
				this.$refs.username.focus()
			} else if (this.loginForm.password === '') {
				this.$refs.password.focus()
			}
		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			// 注册
			studentRegister() {
				this.$router.push({
					name: 'Register'
				})
			},
			checkCapslock(e) {
				const {
					key
				} = e
				this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
			},
			showPwd() {
				if (this.passwordType === 'password') {
					this.passwordType = ''
				} else {
					this.passwordType = 'password'
				}
				this.$nextTick(() => {
					this.$refs.password.focus()
				})
			},
			submitForm() {
				// sessionStorage.setItem('token','dwadawsawd')
				// this.$store.commit('setLogin',true)
				// this.$router.go(0)

				// this.$router.replace('/index')
				// 其它界面要通过token是否合法 来显示界面，若不合法 则不让登陆
				this.$refs['loginForm'].validate(valid => {
					if (valid) {
						this.loading = true
						this.$request({
								url: '/pc/user/login',
								data: this.loginForm,
								method: 'POST'
							})
							.then(res => {
								sessionStorage.setItem('token', res.token)
								if (res.id) {
									this.$store.commit('setLogin', true)
									sessionStorage.setItem('isLogin', 1)
									sessionStorage.setItem('userInfo', JSON.stringify(res))
									this.$router.go(0)
								} else {
									this.$message({
										showClose: true,
										message: res.msg,
										type: 'error'
									})
								}

								this.loading = false
							})
							.catch(() => {
								this.loading = false
							})
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			getOtherQuery(query) {
				return Object.keys(query).reduce((acc, cur) => {
					if (cur !== 'redirect') {
						acc[cur] = query[cur]
					}
					return acc
				}, {})
			}
			// afterQRScan() {
			//   if (e.key === 'x-admin-oauth-code') {
			//     const code = getQueryObject(e.newValue)
			//     const codeMap = {
			//       wechat: 'code',
			//       tencent: 'code'
			//     }
			//     const type = codeMap[this.auth_type]
			//     const codeName = code[type]
			//     if (codeName) {
			//       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
			//         this.$router.push({ path: this.redirect || '/' })
			//       })
			//     } else {
			//       alert('第三方登录失败')
			//     }
			//   }
			// }
		},
		mounted() {
			// 简单写法 还要判断token是否合法
			if (!sessionStorage.isLogin || !sessionStorage.isLogin == 1) {} else {
				this.$router.replace('/index')
			}
		}
	}
</script>

<style lang="scss">
	/* 修复input 背景不协调 和光标变色 */
	/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

	$bg:#283443;
	$light_gray:#fff;
	$cursor: #fff;

	@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	.input-box {
		border: 1px #fff solid;
		width: 520px;
		background-color: rgba(99, 99, 99, 0.3);


	}

	/* reset element-ui css */
	.login-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;

		.el-input {
			height: 47px;
			width: 85%;

			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				color: $light_gray;
				height: 47px;
				caret-color: $cursor;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px $bg inset !important;
					-webkit-text-fill-color: $cursor !important;
				}
			}
		}

		.el-form-item {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			color: #454545;
		}
	}
</style>

<style lang="scss" scoped>
	$bg:#2d3a4b;
	$dark_gray:#889aa4;
	$light_gray:#eee;

	.login-container {
		height: 100%;
		width: 100%;
		// background-color: $bg;
		// background-image: url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fattach.bbs.miui.com%2Fforum%2F201312%2F28%2F124740dp1gp9ip9mzwpxlw.jpg&refer=http%3A%2F%2Fattach.bbs.miui.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1623550613&t=826cda301ece6c34f0f28d1d8c42a579);
		background-image: url(../../assets/beijing.jpg);
		background-repeat: no-repeat;
		background-size: 100vw 100vh;
		overflow: hidden;

		.login-form {
			position: relative;
			width: 460px;
			max-width: 100%;
			// padding: 160px 35px 0;
			padding: 16px 35px 0;
			margin: 0 auto;
			overflow: hidden;
		}

		.tips {
			font-size: 14px;
			color: #fff;
			margin-bottom: 10px;

			span {
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}

		.svg-container {
			padding: 6px 5px 6px 15px;
			vertical-align: middle;
			width: 30px;
			display: inline-block;
		}

		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: $light_gray;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.show-pwd {
			position: absolute;
			right: 10px;
			top: 7px;
			font-size: 16px;
			cursor: pointer;
			user-select: none;
		}

		.thirdparty-button {
			position: absolute;
			right: 0;
			bottom: 6px;
		}

		@media only screen and (max-width: 470px) {
			.thirdparty-button {
				display: none;
			}
		}
	}
</style>
